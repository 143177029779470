import { FC } from 'react';
import IconProps from './IconProps';

const TemplateIcon: FC<IconProps> = (props) => {
  const { onClick, className, accentColor = '#C5A76B', gradient } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  if (gradient) {
    return (
      <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 27 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1.31543" y="2" width="24" height="18.1463" rx="3.56334" stroke="url(#paint0_linear_4183_66104)" strokeWidth="2.03619" />
        <rect x="4.82812" y="5.51221" width="16.9756" height="4.09756" rx="2.04878" stroke="url(#paint1_linear_4183_66104)" strokeWidth="1.52714" />
        <rect x="4.82812" y="13.1218" width="7.02439" height="3.5122" rx="1.7561" stroke="url(#paint2_linear_4183_66104)" strokeWidth="1.52714" />
        <rect x="14.7789" y="13.1218" width="7.02439" height="3.5122" rx="1.7561" stroke="url(#paint3_linear_4183_66104)" strokeWidth="1.52714" />
        <defs>
          <linearGradient id="paint0_linear_4183_66104" x1="1.31543" y1="7.54087" x2="22.8255" y2="19.7022" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint1_linear_4183_66104" x1="4.82812" y1="6.76337" x2="9.68202" y2="15.3596" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint2_linear_4183_66104" x1="4.82812" y1="14.1943" x2="9.62779" y2="18.2978" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint3_linear_4183_66104" x1="14.7789" y1="14.1943" x2="19.5786" y2="18.2978" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      onClick={onClick}
      className={`inline ${cursor} ${className}`}
      width="28"
      height="22"
      viewBox="0 0 28 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="2" y="2" width="24" height="18.1463" rx="3.56334" stroke="currentColor" strokeWidth="2.03619" />
      <rect x="5.5127" y="5.51219" width="16.9756" height="4.09756" rx="2.04878" stroke={accentColor} strokeWidth="1.52714" />
      <rect x="5.5127" y="13.1219" width="7.02439" height="3.5122" rx="1.7561" stroke={accentColor} strokeWidth="1.52714" />
      <rect x="15.4639" y="13.1219" width="7.02439" height="3.5122" rx="1.7561" stroke={accentColor} strokeWidth="1.52714" />
    </svg>
  );
};

export default TemplateIcon;
